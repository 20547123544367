/* eslint-disable no-underscore-dangle */

import CryptoJS from "crypto-js";
import config from "../config";
import moment from "moment";

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${config.NAME_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};
export const removeLocalStorageToken = (navigate) => {
  if (localStorage.getItem(`${config.NAME_KEY}:token`)) {
    localStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
  if (navigate) {
    navigate("/");
  }
};

export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function decodeQueryData(data) {
  return JSON?.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace("?", "")}"}`
  );
}

export function decodeData(data) {
  return decodeURIComponent(data);
}

export function modifiedUrlData(data) {
  let filteredObj = Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => value !== "undefined" && value !== ""
    )
  );
  let obj = {
    limit: 10,
    page: 1,
    // flyingFromID: filteredObj?.flyingFromID,
    packageId: filteredObj?.flyingToID,
    adult: filteredObj?.adult,
    child: filteredObj?.children,
    infant: filteredObj?.infants,
    // withFlight: filteredObj?.flightType,
  };
  if (filteredObj?.packageID) {
    obj["packageType"] = decodeURIComponent(filteredObj?.packageID);
  }
  if (filteredObj?.fromDate) {
    obj["fromDate"] = decodeURIComponent(filteredObj?.fromDate);
  }
  if (filteredObj?.toDate) {
    obj["toDate"] = decodeURIComponent(filteredObj?.toDate);
  }
  // let modifiedString = Object.entries(obj)
  //   .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
  //   .join("?");
  let modifiedString = new URLSearchParams(obj).toString();

  return modifiedString;
}

export function getCommasValue(data) {
  let result = data.length > 1 ? data.join(",") : data[0].toString();
  return result;
}

export const checkValidData = (data) => {
  return data || "-";
};

export function checkURL(url) {
  return url?.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export const firstLetterCaps = (text) => {
  try {
    if (text) {
      const originalString = text.toLowerCase().split("");
      originalString[0] = originalString[0].toUpperCase();
      return originalString.join("");
    } else {
      return text;
    }
  } catch (error) {
    console.log(error);
  }
};

export const formatToINR = (price) => {
  const roundedPrice = Math.round(price);
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(roundedPrice);
};
export function dateFormatter(params, format) {
  return params ? moment(params, "DD-MM-YYYY").format(format) : "-";
}
export function timeFormatter(params, format) {
  return params ? moment(params).format(format) : "-";
}

export function timeCountFormatter(params) {
  let hours = Math.floor(params / 60);
  let minutes = params % 60;
  return `${hours > 0 ? `${hours}h` : "00"}:${
    minutes > 0 ? `${minutes}m` : "00"
  }`;
}

export function calculateDuration(depDate, arrDate) {
  const dep = new Date(depDate);
  const arr = new Date(arrDate);
  const diffMs = arr - dep;
  const diffHours = Math.floor(diffMs / 1000 / 60 / 60);
  const diffMinutes = Math.floor((diffMs / 1000 / 60) % 60);
  return `${diffHours}h ${diffMinutes}m`;
}
